import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { Navbar, Nav } from "react-bootstrap";
import { LinkContainer } from 'react-router-bootstrap';
import { Auth, API } from 'aws-amplify';
import { AppContext } from './libs/contextLib';
import { onError } from './libs/errorLib';
import Routes from './Routes';
import "./App.css";
import { FlagsProvider } from "./libs/featureFlags";

function App() {
  const [isAuthenticated, userHasAuthenticated] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [flags, setFlags] = useState({});
  const history = useHistory();

  useEffect(() => {
    onLoad();
  }, []);

  async function onLoad() {
    try {
      await Auth.currentSession();
      userHasAuthenticated(true);
      const featureFlags = await API.get('gift-exchange-group', '/features');
      setFlags(featureFlags);
    } catch(e) {
      if (e !== 'No current user') {
        onError(e);
      }
    }
    setIsAuthenticating(false);
  }

  async function handleLogout() {
    await Auth.signOut();
    setFlags({});

    userHasAuthenticated(false);

    history.push('/login');
  }

  return (
    !isAuthenticating &&
    <div className="App container">
      <FlagsProvider flags={flags}>
        <Navbar fluid collapseOnSelect>
          <Navbar.Brand>
            <Link to="/">Gift Exchange Group</Link>
          </Navbar.Brand>
          <Navbar.Toggle />
          <Navbar.Collapse>
            <Nav className="ml-auto">
              {isAuthenticated
                ? <>
                    <LinkContainer to="/myList">
                      <Nav.Link>My List</Nav.Link>
                    </LinkContainer>
                    <LinkContainer to="/myGroups">
                      <Nav.Link>My Groups</Nav.Link>
                    </LinkContainer>
                    <Nav.Link onClick={handleLogout}>Logout</Nav.Link>
                  </>
                : <>
                    <LinkContainer to="/signup">
                      <Nav.Link>Signup</Nav.Link>
                    </LinkContainer>
                    <LinkContainer to="/login">
                      <Nav.Link>Login</Nav.Link>
                    </LinkContainer>
                  </>
              }
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        <AppContext.Provider value={{ isAuthenticated, userHasAuthenticated, setFlags }}>
          <Routes />
        </AppContext.Provider>
      </FlagsProvider>
    </div>
  );
}

export default App;