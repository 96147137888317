import React, { useState } from "react";
import { FormGroup, FormLabel } from "react-bootstrap";
import { useHistory } from 'react-router-dom';
import { Auth, API } from 'aws-amplify';
import { useAppContext } from '../libs/contextLib';
import LoaderButton from '../components/LoaderButton';
import ValidatedFormControl from '../components/ValidatedFormControl';
import { onError } from '../libs/errorLib';
import { useFormFields } from '../libs/hooksLib';
import { validateEmail, validatePassword } from '../libs/validateLib';
import "./Login.css";

export default function Login() {
  const history = useHistory();
  const { userHasAuthenticated, setFlags } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);
  const [fields, handleFieldChange] = useFormFields({
    email: '',
    password: ''
  });

  function validateForm() {
    return validateEmail(fields.email) && validatePassword(fields.password);
  }

  async function handleSubmit(event) {
    event.preventDefault();

    setIsLoading(true);

    try {
      await Auth.signIn(fields.email, fields.password);
      const flags = await API.get('gift-exchange-group', '/features');
      setFlags(flags);
      userHasAuthenticated(true);
      history.push('/');
    } catch (e) {
      onError(e);
      setIsLoading(false);
    }
  }

  return (
    <div className="Login">
      <form onSubmit={handleSubmit}>
        <FormGroup controlId="email" bsSize="large">
          <FormLabel>Email</FormLabel>
          <ValidatedFormControl
            autoFocus
            type="email"
            value={fields.email}
            onChange={handleFieldChange}
            validated={validateEmail(fields.email)}
          />
        </FormGroup>
        <FormGroup controlId="password" bsSize="large">
          <FormLabel>Password</FormLabel>
          <ValidatedFormControl
            value={fields.password}
            onChange={handleFieldChange}
            type="password"
            validated={validatePassword(fields.password)}
          />
        </FormGroup>
        <LoaderButton
          block
          bsSize="large"
          isLoading={isLoading}
          disabled={!validateForm()}
          type="submit"
        >
          Login
        </LoaderButton>
      </form>
    </div>
  );
}