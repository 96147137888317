export default {
  apiGateway: {
    REGION: 'us-east-1',
    URL: 'https://wswfrls4vf.execute-api.us-east-1.amazonaws.com/prod'
  },
  cognito: {
    REGION: 'us-east-1',
    USER_POOL_ID: 'us-east-1_ghsfcOguZ',
    APP_CLIENT_ID: '5vj6o1gr3ik53u2ujb0fl1d00q',
    IDENTITY_POOL_ID: 'us-east-1:7aed8ce7-2e56-48d9-b582-e7ee286612a4'
  }
}