import React from "react";
import { FormControl } from 'react-bootstrap';
import classNames from 'classnames';
import './ValidatedFormControl.css';

export default function ValidatedFormControl({
  validated,
  ...props
}) {
  const validatedClass = validated ? 'validated' : 'error';
  return (
    <FormControl
      className={classNames('ValidatedFormControl', validatedClass, props.className)}
      {...props}
    />
  );
}