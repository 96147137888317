import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { API } from 'aws-amplify';
import { onError } from '../libs/errorLib';
import './MyList.css';

export default function Main() {
  const history = useHistory();
  const [items, setItems] = useState([]);
  useEffect(() => {
    onLoad();
  }, []);

  async function onLoad() {
    try {
      const response = await API.get('gift-exchange-group', '/item');
      if (response.items) {
        setItems(response.items);
      }
    } catch(e) {
      onError(e);
    }
  }

  function addItem() {
    history.push('/addItem');
  }

  return (
    <div className="MyList">
      <div className="lander">
        <Button onClick={addItem}>Add Item</Button>
        {items.length > 0 &&
          <table className="itemsTable">
            <tr>
              <th className="itemDescription">Item Description</th>
              <th className="itemURL">URL</th>
            </tr>
            {items.map((item) => {
              return (
                <tr>
                  <td className="itemDescription">{item.description}</td>
                  <td className="itemURL">
                    {item.url && item.url.length > 0 &&
                      <a href={item.url} target="_blank">{item.url}</a>
                    }
                  </td>
                </tr>
              );
            })}
          </table>
        }
      </div>
    </div>
  );
}