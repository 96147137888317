import React, { useState } from "react";
import { FormGroup, FormLabel, FormControl } from "react-bootstrap";
import { useHistory } from 'react-router-dom';
import { API } from 'aws-amplify';
import ValidatedFormControl from '../components/ValidatedFormControl';
import LoaderButton from '../components/LoaderButton';
import { validateFieldNotEmpty } from '../libs/validateLib';
import { useFormFields } from '../libs/hooksLib';
import { onError } from '../libs/errorLib';
import "./AddGroup.css";

export default function AddGroup() {
  const history = useHistory();
  const [fields, handleFieldChange] = useFormFields({
    groupName: ''
  });
  const [isLoading, setIsLoading] = useState(false);

  async function handleSubmit(event) {
    event.preventDefault();

    setIsLoading(true);

    try {
      const response = await API.post('gift-exchange-group', '/group', {
        body: {
          group : {
            groupName: fields.groupName
          }
        }
      });
      if (response !== 'Group added') {
        throw new Error(response);
      }
      history.push('/myGroups');
    } catch(e) {
      onError(e);
      setIsLoading(false);
    }
  }
  
  return (
    <div className="AddGroup">
      <div className="lander">
        <h1>Add Group</h1>
        <form onSubmit={handleSubmit}>
          <FormGroup controlId="groupName" bsSize="large">
            <FormLabel>Group Name</FormLabel>
            <ValidatedFormControl
              autofocus
              type="text"
              value={fields.groupName}
              onChange={handleFieldChange}
              validated={validateFieldNotEmpty(fields.groupName)}
            />
          </FormGroup>
          <LoaderButton
            block
            bsSize="large"
            isLoading={isLoading}
            disabled={!validateFieldNotEmpty(fields.groupName)}
            type="submit"
          >
            Add
          </LoaderButton>
        </form>
      </div>
    </div>
  );
}