import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  FormGroup,
  FormLabel,
  FormText
} from "react-bootstrap";
import { Auth } from 'aws-amplify';
import LoaderButton from "../components/LoaderButton";
import ValidatedFormControl from '../components/ValidatedFormControl';
import { useAppContext } from "../libs/contextLib";
import { useFormFields } from "../libs/hooksLib";
import { validateEmail, validatePassword, validateFieldNotEmpty } from '../libs/validateLib';
import { onError } from "../libs/errorLib";
import "./Signup.css";

export default function Signup() {
  const [fields, handleFieldChange] = useFormFields({
    email: "",
    password: "",
    confirmPassword: "",
    firstName: "",
    lastName: "",
    confirmationCode: ""
  });
  const history = useHistory();
  const [newUser, setNewUser] = useState(null);
  const { userHasAuthenticated } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);

  function validateConfirmPassword() {
    return fields.confirmPassword.length > 0 &&
      fields.password === fields.confirmPassword;
  }

  function validateForm() {
    return (
      validateEmail(fields.email) &&
      validatePassword(fields.password) &&
      validateConfirmPassword() &&
      validateFieldNotEmpty(fields.firstName) &&
      validateFieldNotEmpty(fields.lastName)
    );
  }

  function validateConfirmationForm() {
    return fields.confirmationCode.match(/^[0-9]{6}$/);
  }

  async function handleSubmit(event) {
    event.preventDefault();

    setIsLoading(true);

    try{
      const newUser = await Auth.signUp({
        username: fields.email,
        password: fields.password,
        attributes: {
          email: fields.email,
          given_name: fields.firstName,
          family_name: fields.lastName
        }
      });
      setIsLoading(false);
      setNewUser(newUser);
    } catch(e) {
      onError(e);
      setIsLoading(false);
    }
  }

  async function handleConfirmationSubmit(event) {
    event.preventDefault();

    setIsLoading(true);

    // If user reloads the page before confirming the registration, more
    // work is MSMediaKeyNeededEvent. See the bottom of
    // https://serverless-stack.com/chapters/signup-with-aws-cognito.html

    try {
      await Auth.confirmSignUp(fields.email, fields.confirmationCode);
      await Auth.signIn(fields.email, fields.password);

      userHasAuthenticated(true);
      history.push('/');
    } catch(e) {
      onError(e);
      setIsLoading(false);
    }
  }

  function renderConfirmationForm() {
    return (
      <form onSubmit={handleConfirmationSubmit}>
        <FormGroup controlId="confirmationCode" bsSize="large">
          <FormLabel>Confirmation Code</FormLabel>
          <ValidatedFormControl
            autoFocus
            type="tel"
            onChange={handleFieldChange}
            value={fields.confirmationCode}
            validated={validateConfirmationForm()}
          />
          <FormText color="muted">Please check your email for the code.</FormText>
        </FormGroup>
        <LoaderButton
          block
          type="submit"
          bsSize="large"
          isLoading={isLoading}
          disabled={!validateConfirmationForm()}
        >
          Verify
        </LoaderButton>
      </form>
    );
  }

  function renderForm() {
    return (
      <form onSubmit={handleSubmit}>
        <FormGroup controlId="email" bsSize="large">
          <FormLabel>Email</FormLabel>
          <ValidatedFormControl
            autoFocus
            type="email"
            value={fields.email}
            onChange={handleFieldChange}
            validated={validateEmail(fields.email)}
          />
        </FormGroup>
        <FormGroup controlId="password" bsSize="large">
          <FormLabel>Password</FormLabel>
          <ValidatedFormControl
            type="password"
            value={fields.password}
            onChange={handleFieldChange}
            validated={validatePassword(fields.password)}
          />
          <FormText>Password must be at least 8 characters and include at least one upper case, one lower case, one number and one symbol</FormText>
        </FormGroup>
        <FormGroup controlId="confirmPassword" bsSize="large">
          <FormLabel>Confirm Password</FormLabel>
          <ValidatedFormControl
            type="password"
            onChange={handleFieldChange}
            value={fields.confirmPassword}
            validated={validateConfirmPassword()}
          />
        </FormGroup>
        <FormGroup controlId="firstName" bsSize="large">
          <FormLabel>First Name</FormLabel>
          <ValidatedFormControl
            type="text"
            onChange={handleFieldChange}
            value={fields.firstName}
            validated={validateFieldNotEmpty(fields.firstName)}
          />
        </FormGroup>
        <FormGroup controlId="lastName" bsSize="large">
          <FormLabel>Last Name</FormLabel>
          <ValidatedFormControl
            type="text"
            onChange={handleFieldChange}
            value={fields.lastName}
            validated={validateFieldNotEmpty(fields.lastName)}
          />
        </FormGroup>
        <LoaderButton
          block
          type="submit"
          bsSize="large"
          isLoading={isLoading}
          disabled={!validateForm()}
        >
          Signup
        </LoaderButton>
      </form>
    );
  }

  return (
    <div className="Signup">
      {newUser === null ? renderForm() : renderConfirmationForm()}
    </div>
  );
}