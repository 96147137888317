import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { API } from 'aws-amplify';
import { onError } from '../libs/errorLib';
import { Flag, useFlags } from '../libs/featureFlags';
import './MyGroups.css';

export default function Main() {
  const history = useHistory();
  const [groups, setGroups] = useState([]);
  const flags = useFlags();
  useEffect(() => {
    onLoad();
  }, []);

  async function onLoad() {
    if (flags.GetGroups) {
      try {
        const response = await API.get('gift-exchange-group', '/group');
        if (response.groups) {
          setGroups(response.groups);
        }
      } catch(e) {
        onError(e);
      }
    }
  }

  function addGroup() {
    history.push('/addGroup');
  }

  return (
    <div className="MyGroups">
      <div className="lander">
        <Flag
          name={['AddGroup']}
          render={() => <Button onClick={addGroup}>Add Group</Button>}
        />
        <Flag
          name={['GetGroups']}
          render={() => groups.length > 0 &&
            <table className="groupsTable">
              <tr>
                <th className="groupName">Group Name</th>
                <th className="owner">Owner</th>
              </tr>
              {groups.map((group) => {
                return (
                  <tr>
                    <td className="groupName">{group.groupName}</td>
                    <td className="owner">
                      {group.isOwner ? 'X' : '&nbsp;'}
                    </td>
                  </tr>
                );
              })}
            </table>
          }
        />
      </div>
    </div>
  );
}