import React from "react";
import { Route, Switch } from "react-router-dom";
import { useAppContext } from './libs/contextLib';
import Home from "./containers/Home";
import MyList from './containers/MyList';
import MyGroups from './containers/MyGroups';
import Login from './containers/Login';
import Signup from './containers/Signup';
import AddItem from './containers/AddItem';
import AddGroup from './containers/AddGroup';
import NotFound from './containers/NotFound';

export default function Routes() {
  const { isAuthenticated } = useAppContext();
  return (
    <Switch>
      <Route exact path="/">
        <Home />
      </Route>
      <Route exact path="/login">
        <Login />
      </Route>
      <Route exact path="/signup">
        <Signup />
      </Route>
      {isAuthenticated &&
        <>
          <Route exact path="/myList">
            <MyList />
          </Route>
          <Route exact path="/myGroups">
            <MyGroups />
          </Route>
          <Route exact path="/addItem">
            <AddItem />
          </Route>
          <Route exact path="/addGroup">
            <AddGroup />
          </Route>
        </>
      }
      {/* Finally, catch all unmatched routes */}
      <Route>
        <NotFound />
      </Route>
    </Switch>
  );
}
