import React from "react";
import { Button } from 'react-bootstrap';
import { useHistory } from "react-router-dom";
import { useAppContext } from '../libs/contextLib';
import "./Home.css";

export default function Home() {
  const { isAuthenticated } = useAppContext();
  const history = useHistory();

  function onClickMyList() {
    history.push('/myList');
  }

  function onClickMyGroups() {
    history.push('/myGroups');
  }

  function onClickSignUp() {
    history.push('/signup');
  }

  function onClickLogIn() {
    history.push('/login');

  }
  return (
    <div className="Home">
      <div className="lander">
        <h1>Gift Exchange Group</h1>
        {isAuthenticated 
        ? <>
            <Button onClick={onClickMyList}>My List</Button>
            &nbsp;
            <Button onClick={onClickMyGroups}>My Groups</Button>
          </>
        : <>
          <Button onClick={onClickSignUp}>Sign Up</Button>
          &nbsp;
          <Button onClick={onClickLogIn}>Log In</Button>
        </>
        }
      </div>
    </div>
  );
}